<style>
.add-owner {
    float: right;
}

.ranges {
    max-height: 200px;
    overflow: scroll;
}



.alert-primary {
    width: 100%;
}
</style>
<template>
    <div>
        <!-- Form Modal -->
        <b-modal id="modal-season-form" ref="myModal" centered no-close-on-backdrop no-close-on-esc size="lg" @ok="submit">
            <!-- Custom Modal Header -->
            <template #modal-header="{ close }">
                <!-- Emulate built in modal header close button action -->
                <h5>Issue Ticket</h5>

                <feather-icon class="ml-4 pr-0 cursor-pointer" icon="XIcon" size="16" @click="close()" />
            </template>

            <!-- Custom Modal Footer -->
            <template #modal-footer="{ ok, cancel }">
                <b-button size="md" variant="primary" @click="ok()" :disabled="saving">
                    <b-spinner small class="mr-1" label="Small Spinner" v-if="saving" />
                    <span v-if="!saving">Issue</span>
                    <span v-if="saving">Issueing...</span>
                </b-button>
                <b-button size="md" @click="cancel()" variant="outline-secondary">
                    Cancel
                </b-button>


            </template>

            <!-- Data Form in Modal Body -->
            <template #default="{ }">

                <b-row>
                    <b-col cols="12">
                        <!-- <b-alert show variant="danger" v-if="errosOcurs" class="p-1">{{ errosOcurs.message }}</b-alert> -->

                        <b-alert show variant="danger" v-if="errosOcurs">
                            <h4 class="alert-heading">
                                {{ errosOcurs.message }}
                            </h4>
                            <div class="alert-body">
                                <div v-for="(errosOcurs, field) in errosOcurs.errors" :key="field">
                                    <strong>{{ field }}:</strong>
                                    <ul>
                                        <li v-for="message in errosOcurs" :key="message">{{ message }}</li>
                                    </ul>
                                </div>
                            </div>
                        </b-alert>


                    </b-col>
                </b-row>
                <validation-observer ref="dataForm" #default="{ invalid }">
                    <b-form ref="form" @submit.stop.prevent="handleCreate">
                        <b-row>

                            <b-alert show variant="primary" v-if="requisitionDetail.notes">
                                <h4 class="alert-heading">
                                    Requisition Number {{ requisitionDetail.number }}
                                </h4>
                                <div class="alert-body">
                                    <ul>
                                        <li>For Society <span style="font-weight:bold;">
                                                {{ requisitionDetail.society.name }}</span>
                                            at Sale {{ requisitionDetail.sale.number }} on {{
                                                requisitionDetail.sale.market.name.toUpperCase() }} :- {{
        requisitionDetail.sale.market.code }}</li>
                                    </ul>

                                    <Accordion>
                                        <AccordionTab v-for="note in requisitionDetail.notes" :key="note.number"
                                            :header="'Issue Note Number  ' + note.number">
                                            <table class="table">
                                                <thead>
                                                    <tr class="text-center">
                                                        <th>Start</th>
                                                        <th>End</th>
                                                        <!-- <th width="10%">Quantity</th> -->
                                                        <th width="3%"> </th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    <tr v-for="element, index in  note.ranges" :key="index">
                                                        <td scope="row">
                                                            <div>

                                                                <b-form-input id="start" name="start"
                                                                    v-model="element.start" size="md" />

                                                            </div>
                                                        </td>

                                                        <td>
                                                            <div>

                                                                <b-form-input id="end" name="end" v-model="element.end"
                                                                    size="md" />

                                                            </div>

                                                        </td>
                                                        <td>
                                                            <b-button variant="outline-danger" size="sm">
                                                                <feather-icon icon="TrashIcon" size="11"
                                                                    class="text-danger stroke-current" />
                                                            </b-button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td scope="row"></td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </AccordionTab>


                                    </Accordion>
                                </div>
                            </b-alert>
                        </b-row>

                        <b-row>
                            <b-col>
                                <b-button size="sm" class="add-owner mb-1" variant="outline-primary"
                                    @click="addElement()">Add Range</b-button>

                            </b-col>
                            <table class="table">
                                <thead>
                                    <tr class="text-center">
                                        <th>Start</th>
                                        <th>End</th>
                                        <!-- <th width="10%">Quantity</th> -->
                                        <th width="3%"> </th>
                                    </tr>
                                </thead>
                                <tbody>

                                    <tr v-for="element, index in  requisition.ranges" :key="index">
                                        <td scope="row">
                                            <div>

                                                <b-form-input id="share" name="start" v-model="element.start" size="md" />

                                            </div>
                                        </td>

                                        <td>
                                            <div>

                                                <b-form-input id="share" name="end" v-model="element.end" size="md" />

                                            </div>

                                        </td>
                                        <td>
                                            <b-button variant="outline-danger" size="sm" @click="removeRow(index)">
                                                <feather-icon icon="TrashIcon" size="11"
                                                    class="text-danger stroke-current" />
                                            </b-button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td scope="row"></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                        </b-row>

                        <!-- <pre>
                            {{ requisition }}
                        </pre> -->
                    </b-form>
                </validation-observer>
            </template>




        </b-modal>
        <!-- ./Form Modal -->

        <!-- Form Modal -->
        <b-modal ref="myModalPcn" centered no-close-on-backdrop no-close-on-esc :hide-footer=true size="lg">
            <!-- Custom Modal Header -->
            <template #modal-header="{ close }">
                <!-- Emulate built in modal header close button action -->
                <h5>Tickets Requisition</h5>
                <feather-icon class="ml-4 pr-0 cursor-pointer" icon="PrinterIcon" size="16" />
                <feather-icon class="ml-4 pr-0 cursor-pointer" icon="XIcon" size="16" @click="close()" />
            </template>


            <!-- Data Form in Modal Body -->
            <template #default="{ }">
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col">SN</th>
                            <th scope="col">Barcode</th>
                            <th scope="col">NGrade</th>
                            <th scope="col">HGrade</th>
                            <th scope="col">Weight</th>
                            <th scope="col">Value</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(tiket, index)  in tikets">
                            <th scope="row">{{ index + 1 }}</th>
                            <td>{{ tiket.barcode }}</td>
                            <td>{{ tiket.grade }}</td>
                            <td>{{ tiket.houseGrade }}</td>
                            <td>{{ tiket.mass }}</td>
                            <td>{{ tiket.value.toFixed(3) }}</td>
                        </tr>
                    </tbody>

                    <tfoot>
                        <tr style="font-weight:bold;">
                            <td colspan="4" class="footer">&nbsp;</td>
                            <td class="footer">{{ totalWeight.toFixed(3) }}</td>
                            <td class="footer">{{ tValue.toFixed(3) }} </td>
                        </tr>
                    </tfoot>
                </table>
            </template>
        </b-modal>
        <!-- ./Form Modal -->

        <!-- Form Modal -->
        <b-modal id="modal-season-form" ref="myViewModal" centered no-close-on-backdrop no-close-on-esc size="lg"
            @ok="submit">
            <!-- Custom Modal Header -->
            <template #modal-header="{ close }">
                <!-- Emulate built in modal header close button action -->
                <h5>Issued Ticket</h5>

                <feather-icon class="ml-4 pr-0 cursor-pointer" icon="XIcon" size="16" @click="close()" />
            </template>

            <!-- Custom Modal Footer -->
            <template #modal-footer="{ ok, cancel }">
                <b-button size="md" variant="primary" @click="ok()" :disabled="saving">
                    <b-spinner small class="mr-1" label="Small Spinner" v-if="saving" />
                    <span v-if="!saving">Issue</span>
                    <span v-if="saving">Issueing...</span>
                </b-button>
                <b-button size="md" @click="cancel()" variant="outline-secondary">
                    Cancel
                </b-button>


            </template>

            <!-- Data Form in Modal Body -->
            <template #default="{ }">


            </template>




        </b-modal>
        <!-- ./Form Modal -->

        <!-- Filters -->
        <b-card no-body>
            <b-card-header class="pb-50">
                <h5>Filter Location</h5>
            </b-card-header>
            <b-card-body>

                <b-row v-if="role.id == 5">
                    <b-col cols="3">
                        <b-form-group labe-for="region_id" label="Region">
                            <validation-provider #default="{ errors }" name="Region" rules="">
                                <b-form-select id="region_id" name="region_id" size="md" v-model="filter.region_id"
                                    @change="changeRegion(filter.region_id)">
                                    <b-form-select-option :value="null">Select...</b-form-select-option>
                                    <b-form-select-option v-for="location in locations" :key="location.id"
                                        :value="location">
                                        {{ location.name.toUpperCase() }}
                                    </b-form-select-option>
                                </b-form-select>
                                <small class="text-danger">{{ errors[0] }}</small>
                                <small class="text-danger" v-if="serverErrors && serverErrors.region_id">{{
                                    serverErrors.region_id[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <b-col cols="3">
                        <b-form-group labe-for="location_id" label="Area">
                            <validation-provider #default="{ errors }" name="Area" rules="">
                                <b-form-select id="location_id" name="location_id" size="md" v-model="filter.location_id"
                                    @change="getAreaMarket(filter.location_id)">
                                    <b-form-select-option :value="null">Select...</b-form-select-option>
                                    <b-form-select-option v-for="location in locId.children" :key="location.id"
                                        :value="location.id">
                                        {{ location.name.toUpperCase() }}
                                    </b-form-select-option>
                                </b-form-select>
                                <small class="text-danger">{{ errors[0] }}</small>
                                <small class="text-danger" v-if="serverErrors && serverErrors.location_id">{{
                                    serverErrors.location_id[0] }}</small>
                            </validation-provider>
                        </b-form-group>

                    </b-col>

                    <b-col cols="3">
                        <b-form-group labe-for="market_id" label="Market Center">
                            <validation-provider #default="{ errors }" name="Market Center" rules="">
                                <b-form-select id="market_id" name="market_id" size="md" v-model="filter.market_id"
                                    @change="getAreaMarketSale(filter.market_id)">
                                    <b-form-select-option :value="null">Select...</b-form-select-option>
                                    <b-form-select-option v-for="market in markets" :key="market.id" :value="market.id">
                                        {{ market.name.toUpperCase() }}
                                    </b-form-select-option>
                                </b-form-select>
                                <small class="text-danger">{{ errors[0] }}</small>
                                <small class="text-danger" v-if="serverErrors && serverErrors.market_id">{{
                                    serverErrors.market_id[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <b-col cols="3">
                        <b-form-group labe-for="sale_id" label="Sale">
                            <validation-provider #default="{ errors }" name="Sale" rules="">
                                <b-form-select id="sale_id" name="sale_id" size="md" v-model="filter.sale_id"
                                    @change="changeSale($event)">
                                    <b-form-select-option :value="null">Select...</b-form-select-option>
                                    <b-form-select-option v-for="sale in sales" :key="sale.id" :value="sale">
                                        {{ sale.number }}
                                    </b-form-select-option>
                                </b-form-select>
                                <small class="text-danger">{{ errors[0] }}</small>
                                <small class="text-danger" v-if="serverErrors && serverErrors.sale_id">{{
                                    serverErrors.sale_id[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <b-col cols="3">
                        <b-form-group labe-for="society_id" label="Society">
                            <validation-provider #default="{ errors }" name="Society" rules="">
                                <b-form-select id="society_id" name="society_id" size="md" v-model="filter.society_id"
                                    @change="changeSociet(filter.society_id)">
                                    <b-form-select-option :value="null">Select...</b-form-select-option>
                                    <b-form-select-option v-for="society in societies" :key="society.id"
                                        :value="society.id">
                                        {{ society.name }}
                                    </b-form-select-option>
                                </b-form-select>
                                <small class="text-danger">{{ errors[0] }}</small>
                                <small class="text-danger" v-if="serverErrors && serverErrors.society_id">{{
                                    serverErrors.society_id[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                </b-row>

                <b-row v-if="role.id != 5">
                    <b-col cols="3" v-if="locId.id > 0">
                        <b-form-group labe-for="location_id" label="Area">
                            <validation-provider #default="{ errors }" name="Area" rules="">
                                <b-form-select id="location_id" name="location_id" size="md" v-model="filter.location_id"
                                    @change="getAreaMarket(filter.location_id)">
                                    <b-form-select-option :value="null">Select...</b-form-select-option>
                                    <b-form-select-option v-for="location in locId.children" :key="location.id"
                                        :value="location.id">
                                        {{ location.name.toUpperCase() }}
                                    </b-form-select-option>
                                </b-form-select>
                                <small class="text-danger">{{ errors[0] }}</small>
                                <small class="text-danger" v-if="serverErrors && serverErrors.location_id">{{
                                    serverErrors.location_id[0] }}</small>
                            </validation-provider>
                        </b-form-group>

                    </b-col>
                    <b-col cols="3" v-if="locId.id > 0">
                        <b-form-group labe-for="market_id" label="Market Center">
                            <validation-provider #default="{ errors }" name="Market Center" rules="">
                                <b-form-select id="market_id" name="market_id" size="md" v-model="filter.market_id"
                                    @change="getAreaMarketSale(filter.market_id)">
                                    <b-form-select-option :value="null">Select...</b-form-select-option>
                                    <b-form-select-option v-for="market in markets" :key="market.id" :value="market.id">
                                        {{ market.name.toUpperCase() }}
                                    </b-form-select-option>
                                </b-form-select>
                                <small class="text-danger">{{ errors[0] }}</small>
                                <small class="text-danger" v-if="serverErrors && serverErrors.market_id">{{
                                    serverErrors.market_id[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                    <b-col cols="3" v-if="locId.id > 0">
                        <b-form-group labe-for="sale_id" label="Sale">
                            <validation-provider #default="{ errors }" name="Sale" rules="">
                                <b-form-select id="sale_id" name="sale_id" size="md" v-model="filter.sale_id"
                                    @change="changeSale($event)">
                                    <b-form-select-option :value="null">Select...</b-form-select-option>
                                    <b-form-select-option v-for="sale in sales" :key="sale.id" :value="sale">
                                        {{ sale.number }}
                                    </b-form-select-option>
                                </b-form-select>
                                <small class="text-danger">{{ errors[0] }}</small>
                                <small class="text-danger" v-if="serverErrors && serverErrors.sale_id">{{
                                    serverErrors.sale_id[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                    <b-col cols="3" v-if="locId.id > 0">
                        <b-form-group labe-for="society_id" label="Society">
                            <validation-provider #default="{ errors }" name="Society" rules="">
                                <b-form-select id="society_id" name="society_id" size="md" v-model="filter.society_id"
                                    @change="changeSociet(filter.society_id)">
                                    <b-form-select-option :value="null">Select...</b-form-select-option>
                                    <b-form-select-option v-for="society in societies" :key="society.id"
                                        :value="society.id">
                                        {{ society.name }}
                                    </b-form-select-option>
                                </b-form-select>
                                <small class="text-danger">{{ errors[0] }}</small>
                                <small class="text-danger" v-if="serverErrors && serverErrors.society_id">{{
                                    serverErrors.society_id[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-card-body>
        </b-card>
        <!-- ./Filters -->

        <b-card no-body class="mb-0">
            <div class="m-2">
                <!-- Table Top -->
                <b-row>
                    <!-- Per Page -->
                    <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                        <label>Show</label>
                        <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="perPageOptions" :clearable="false"
                            class="per-page-selector d-inline-block mx-50 select-size-sm" />
                        <label>entries</label>
                    </b-col>
                    <!-- ./Per Page -->

                    <!-- Search & Button -->
                    <b-col cols="12" md="6">
                        <div class="d-flex align-items-center justify-content-end">
                            <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="Search..."
                                size="md" />

                        </div>
                    </b-col>
                    <!-- ./Search & Button-->
                </b-row>
                <!-- ./Table Top -->
            </div>

            <!-- Table -->
            <b-table striped hover small ref="records" class="position-relative" :items="fetch" responsive :fields="columns"
                primary-key="id" :sort-by.sync="sortBy" show-empty empty-text="No records found"
                :sort-desc.sync="isSortDirDesc">
                <template #cell(address)="data">
                    {{ data.item.address ? data.item.address.location.name.toUpperCase() : '' }}
                </template>

                <template #cell(approved)="data">
                    <!-- <b-badge pill :variant="`light-${resolveStatusVariant(data.item.approved)}`" class="text-capitalize">
                        <span v-if="data.item.approved == true">Yes</span>
                        <span v-else-if="data.item.approved == false">No</span>
                    </b-badge> -->

                    <b-form-checkbox class="custom-control-success ml-4" name="check-button" switch
                        @change="handleCheckboxChange($event, data.item.id)" :checked="data.item.approved">
                        <span class="switch-icon-left">
                            <feather-icon icon="CheckCircleIcon" />
                        </span>
                    </b-form-checkbox>
                </template>

                <template #cell(description)="data">
                    <span class="text-nowrap">
                        {{ data.item.summaries[0].description }}
                    </span>
                </template>

                <template #cell(market)="data">
                    <span class="text-nowrap">
                        {{ data.item.sale.market.name }}
                    </span>
                </template>

                <template #cell(sale)="data">
                    <span class="text-nowrap">
                        {{ data.item.sale.number }}
                    </span>
                </template>

                <template #cell(society)="data">
                    <span class="text-nowrap">
                        {{ data.item.society.name }}
                    </span>
                </template>

                <template #cell(summaries)="data">
                    <span class="text-nowrap">
                        {{ data.item.summaries[0].quantity }}
                    </span>
                </template>

                <!-- Column: Actions -->
                <template #cell(actions)="data">
                    <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">

                        <template #button-content>
                            <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
                        </template>

                        <b-dropdown-item @click="invokeCreateForm(data.item)">
                            <feather-icon icon="FileTextIcon" />
                            <span class="align-middle ml-50">Issue Ticket</span>
                        </b-dropdown-item>

                        <b-dropdown-item @click="approveRequisition(data.item)" v-if="data.item.approved == false">
                            <feather-icon icon="CheckIcon" />
                            <span class="align-middle ml-50">Approve Requisition</span>
                        </b-dropdown-item>

                        <b-dropdown-item @click="viewPcn(data.item.tickets)">
                            <feather-icon icon="EyeIcon" />
                            <span class="align-middle ml-50">View Requisition</span>
                        </b-dropdown-item>

                    </b-dropdown>
                </template>
            </b-table>

            <!-- Table Footer -->
            <div class="mx-2 mb-2">
                <b-row>
                    <b-col cols="12" sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-start">
                        <span class="text-muted">Showing {{ from }} to {{ to }} of {{ totalRecords }} entries</span>
                    </b-col>
                    <!-- Pagination -->
                    <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
                        <b-pagination v-model="currentPage" :total-rows="totalRecords" :per-page="perPage" first-number
                            last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18" />
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18" />
                            </template>
                        </b-pagination>
                    </b-col>
                </b-row>
            </div>
        </b-card>
    </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
    BRow, BCol, BCard, BCardHeader, BCardBody, BTable, BPagination,
    BForm, BFormGroup,
    BButton, BFormSelect, BFormSelectOption, BFormInput, BDropdown, BDropdownItem,
    BSpinner, BFormCheckbox, BBadge, BAlert,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { required } from '@validations'
import store from '@/store'
import { ref, onUnmounted, onMounted, reactive } from '@vue/composition-api'

import requisitionsStoreModule from '@/views/cromis/sales/requisition/requisitionsStoreModule'
import useRequisitionsList from '@/views/cromis/sales/requisition/useRequisitionsList'
import Panel from 'primevue/panel';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';

export default {
    props: {},
    components: {
        BRow, BCol, BCard, BCardHeader, BCardBody, BTable, BPagination,
        BForm, BFormGroup,
        BButton, BFormSelect, BFormSelectOption, BFormInput, vSelect, BDropdown, BDropdownItem,
        BSpinner,
        ValidationObserver, ValidationProvider, BFormCheckbox, BBadge, BAlert, Panel, Accordion, AccordionTab
    },
    directives: {},
    watch: {
        userInput(newInput, oldInput) {
            // Update default value when user input changes
            if (!isNaN(newInput)) {
                this.defaultValue -= (newInput - oldInput);
            } else {
                // Handle case where userInput is NaN (e.g., after deleting the number)
                this.defaultValue = this.defaultValue;
            }
        },
    },
    setup(props, context) {
        const dataForm = ref(null)
        const myModal = ref(null)
        const myViewModal = ref(null)
        const myModalPcn = ref(null)
        const myRangeModal = ref(null)
        const saving = ref(false)
        const serverErrors = ref(null)
        const errosOcurs = ref(null)
        const selections = ref([])
        const formSelections = ref([])
        const levels = ref(null)
        const companies = ref([])
        const societies = ref([])
        const markets = ref([])
        const requisitionDetail = ref(null)
        const locations = ref([])
        const sales = ref([])
        const tikets = ref([])
        const totalWeight = ref(0)
        const tValue = ref(0)
        const children = ref([])
        const requisition = ref({
            requisition_id: null,
            type: null,
            ranges: [
                {
                    start: null,
                    end: null,
                }
            ]
        })
        const filter = ref({
            region_id: null,
            location_id: null,
            market_id: null,
            sale_id: null,
            society_id: null
        })

        const range = ref({
            id: null,
            requisition_id: null,
            number: null,
            start: null,
            end: null,
            quantity: null,
            hasMissing: false,
            lastBarcode: null,
            direction: null,
            count: null
        })

        const locId = ref(JSON.parse(localStorage.getItem('userData')).location)
        const role = ref(JSON.parse(localStorage.getItem('userData')).roles[0])
        console.log(locId)


        const addElement = () => {
            requisition.value.ranges.push({ start: '', end: '' })

            if (requisition.value.ranges.length > 0) {


                console.log(requisition.value.ranges)
            }
        }

        const removeRow = (index) => {
            console.log(index)
            if (requisition.value.ranges.length > 1) {
                requisition.value.ranges.splice(index, 1)
                requisition.value.ranges = requisition.value.ranges
            }
        }





        const CROMIS_STORE_MODULE_NAME = 'cromis-requisition'
        // Register module
        if (!store.hasModule(CROMIS_STORE_MODULE_NAME)) store.registerModule(CROMIS_STORE_MODULE_NAME, requisitionsStoreModule)

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(CROMIS_STORE_MODULE_NAME)) store.unregisterModule(CROMIS_STORE_MODULE_NAME)
        })

        onMounted(async () => {


            await store.dispatch('cromis-requisition/locations')
                .then(response => {
                    locations.value = response.data.locations

                    console.log(response)
                })
                .catch(error => {
                    console.log('got error', error)
                })
        })

        const handleCheckboxChange = async (event, id) => {

            console.log(event)
            let payload = {
                "approved": event
            }

            await store.dispatch('cromis-requisition/update', { id: id, data: payload })
                .then(response => {

                    console.log(response)
                    refetch()
                    saving.value = false


                    context.root.$swal({
                        icon: 'success',
                        text: `Changes to Requisition has been saved successfully!`,
                        showConfirmButton: true,
                        timer: 3000,
                        customClass: {
                            confirmButton: 'btn btn-success',
                        },
                        buttonsStyling: false,
                    })
                })
                .catch(error => {
                    saving.value = false
                    console.log(error)
                    if (error.response.status === 422) {
                        serverErrors.value = error.response.data.errors
                    }
                    else {
                        context.root.$swal({
                            icon: 'error',
                            title: 'Server Error',
                            text: 'Something went wrong. See tech support',
                            showConfirmButton: true,
                            customClass: {
                                confirmButton: 'btn btn-danger',
                            },
                            buttonsStyling: false,
                        })
                    }
                })
        }


        const populateSelections = (locations) => {
            for (let i = 0; i < locations.length; i++) {
                for (let x = 0; x < selections.value.length; x++) {
                    if (locations[i].level.name.toLowerCase() === selections.value[x].level) {
                        selections.value[x].locations.push(locations[i])

                        if (x === 0) {
                            formSelections.value[x].locations.push(locations[i])
                        }
                    }
                }
            }
        }

        const changeFilters = async (location, index) => {
            if (!location) {
                for (let i = index + 1; i < selections.value.length; i++) {
                    selections.value[i].locations.splice(0)
                    selections.value[i].model = null
                }

                if (index > 0) {
                    locationFilter.value = selections.value[index - 1].model.id
                }
                else {
                    locationFilter.value = null
                }
            }
            else {
                if (selections.value[index + 1]) {
                    selections.value[index + 1].locations.splice(0)
                }

                location.children.map((child) => {
                    selections.value[index + 1].locations.push(child)
                })

                if (location.children.length > 0) {
                    selections.value[index + 1].model = null
                }

                locationFilter.value = selections.value[index].model.id
                if (index === selections.value.length - 1) {
                    requisition.value.location_id = locationFilter.value
                }
            }
        }

        const changeFormSelection = async (location, index) => {
            if (!location) {
                for (let i = index + 1; i < formSelections.value.length; i++) {
                    formSelections.value[i].locations.splice(0)
                    formSelections.value[i].model = null
                }

                requisition.value.location_id = null
            }
            else {
                requisition.value.location_id = null

                if (formSelections.value[index + 1]) {
                    formSelections.value[index + 1].locations.splice(0)
                }

                location.children.map((child) => {
                    formSelections.value[index + 1].locations.push(child)
                })

                if (location.children.length > 0) {
                    formSelections.value[index + 1].model = null
                }

                if (index === formSelections.value.length - 1) {
                    requisition.value.location_id = location.id
                }
            }
        }

        const viewPcn = (item) => {
            tikets.value = item

            for (let index = 0; index < tikets.value.length; index++) {
                console.log(tikets.value[index])
                totalWeight.value += parseFloat(tikets.value[index].mass);
                tValue.value += parseFloat(tikets.value[index].value);

            }

            myModalPcn.value.show()
        }


        const {
            fetch,
            columns,
            perPage,
            currentPage,
            totalRecords,
            from,
            to,
            meta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            records,
            refetch,

            // Filers
            resolveStatusVariant,
            resolveReceivedVariant,
            locationFilter,
            societyFilter,
            marketFilter
        } = useRequisitionsList()

        // Form Methods
        const invokeUpdateForm = (item) => {
            serverErrors.value = null
            errosOcurs.value = null


            requisition.value = {
                requisition_id: item.id,
                type: item.type,
                location_id: null,
                ranges: item.ranges,
            }

            myModal.value.show()
        }

        const invokeCreateForm = (item) => {
            formSelections.value.map((form) => {
                form.model = null
            })
            requisitionDetail.value = item
            console.log(requisitionDetail.value)
            requisition.value = {
                requisition_id: item.id,
                ranges: [
                    {
                        start: null,
                        end: null,
                    }
                ]
            }

            myModal.value.show()
        }

        const approveRequisition = (item) => {
            console.log(item)
            handleApprove(item)
        }


        const invokeRangeUpdateForm = (par) => {
            formSelections.value.map((form) => {
                form.model = null
            })

            console.log(par)

            range.value = {
                id: par.ranges[0].id,
                requisition_id: par.id,
                number: par.number,
                start: par.ranges[0].start,
                end: par.ranges[0].end,
                quantity: par.ranges[0].quantity,
                hasMissing: null,
                lastBarcode: null,
                direction: null,
                count: null
            }

            myRangeModal.value.show()
        }

        const getAreaMarket = async () => {
            locationFilter.value = filter.value.location_id

            await store.dispatch('cromis-requisition/markets', { location_id: filter.value.location_id })
                .then(response => {
                    markets.value = response.data.markets

                    console.log(response)
                })
                .catch(error => {
                    console.log('got error', error)
                })
        }

        const getAreaMarketSale = async () => {
            marketFilter.value = filter.value.market_id
            await store.dispatch('cromis-requisition/sales', { market_id: filter.value.market_id })
                .then(response => {
                    sales.value = response.data.sales
                    societies.value = response.data.sales.societies
                    console.log(response)
                })
                .catch(error => {
                    console.log('got error', error)
                })
        }

        const changeSociet = async (id) => {

            societyFilter.value = id
        }

        const changeSale = async (event) => {
            societies.value = event.societies
            // filter.value.sale_id = event.id
        }

        const changeRegion = async (event) => {

            locId.value = event
            console.log(locId.value)
            locationFilter.value = event.id
        }




        const isFormValid = async () => {
            let isValid = false
            await dataForm.value.validate().then(success => {
                isValid = success
            })

            return isValid
        }

        const submit = (bvModalEvt) => {
            bvModalEvt.preventDefault()

            serverErrors.value = null
            handleCreate()
        }

        const submitUpdateRange = (bvModalEvt) => {
            bvModalEvt.preventDefault()

            serverErrors.value = null
            handleUpdateRange(range.value)
        }




        const handleCreate = async () => {
            saving.value = true
            const valid = await isFormValid()

            if (!valid) {
                saving.value = false
                return
            }


            await store.dispatch('cromis-requisition/requisition', requisition.value)
                .then(response => {
                    refetch()
                    saving.value = false

                    myModal.value.hide()

                    context.root.$swal({
                        icon: 'success',
                        text: `Ticket has been issued successfully!`,
                        showConfirmButton: true,
                        timer: 3000,
                        customClass: {
                            confirmButton: 'btn btn-primary',
                        },
                        buttonsStyling: false,
                    })
                })
                .catch(error => {
                    console.log(error.response.data)
                    errosOcurs.value = error.response.data
                    saving.value = false
                    if (error.response.status === 422) {
                        serverErrors.value = error.response.data
                    }
                    else {
                        context.root.$swal({
                            icon: 'error',
                            title: 'Server Error',
                            text: 'Something went wrong. See tech support',
                            showConfirmButton: true,
                            customClass: {
                                confirmButton: 'btn btn-danger',
                            },
                            buttonsStyling: false,
                        })
                    }
                })
        }



        const handleApprove = async (item) => {

            saving.value = true

            let data = {
                "approved": true
            }

            console.log(item)
            console.log(data)

            await store.dispatch('cromis-requisition/update', { id: item.id, data: data })
                .then(response => {
                    refetch()
                    saving.value = false

                    context.root.$swal({
                        icon: 'success',
                        text: `Approved has been made successfully!`,
                        showConfirmButton: true,
                        timer: 3000,
                        customClass: {
                            confirmButton: 'btn btn-success',
                        },
                        buttonsStyling: false,
                    })
                })
                .catch(error => {
                    saving.value = false
                    errosOcurs.value = error.response.data
                    console.log(error.response)
                    if (error.response.status === 422) {
                        serverErrors.value = error.response.data.errors

                        console.log(serverErrors)
                    }
                    else {
                        context.root.$swal({
                            icon: 'error',
                            title: 'Server Error',
                            text: 'Something went wrong. See tech support',
                            showConfirmButton: true,
                            customClass: {
                                confirmButton: 'btn btn-danger',
                            },
                            buttonsStyling: false,
                        })
                    }
                })
        }


        const handleUpdateRange = async (item) => {
            console.log(item)
            saving.value = true
            const valid = await isFormValid()

            if (!valid) {
                saving.value = false
                return
            }

            await store.dispatch('cromis-requisition/range', { id: item.id, data: item })
                .then(response => {
                    refetch()
                    saving.value = false

                    myModal.value.hide()

                    context.root.$swal({
                        icon: 'success',
                        text: `Changes to requisition range has been saved successfully!`,
                        showConfirmButton: true,
                        timer: 3000,
                        customClass: {
                            confirmButton: 'btn btn-success',
                        },
                        buttonsStyling: false,
                    })
                })
                .catch(error => {
                    saving.value = false
                    errosOcurs.value = error.response.data
                    console.log(error.response)
                    if (error.response.status === 422) {
                        serverErrors.value = error.response.data.errors

                        console.log(serverErrors)
                    }
                    else {
                        context.root.$swal({
                            icon: 'error',
                            title: 'Server Error',
                            text: 'Something went wrong. See tech support',
                            showConfirmButton: true,
                            customClass: {
                                confirmButton: 'btn btn-danger',
                            },
                            buttonsStyling: false,
                        })
                    }
                })
        }


        const handleUpdate = async (item) => {
            console.log(item)
            saving.value = true
            const valid = await isFormValid()

            if (!valid) {
                saving.value = false
                return
            }

            await store.dispatch('cromis-requisition/update', { id: item.id, data: item })
                .then(response => {
                    refetch()
                    saving.value = false

                    myModal.value.hide()

                    context.root.$swal({
                        icon: 'success',
                        text: `Changes to requisition center ${response.data.code} ${response.data.name} has been saved successfully!`,
                        showConfirmButton: true,
                        timer: 3000,
                        customClass: {
                            confirmButton: 'btn btn-success',
                        },
                        buttonsStyling: false,
                    })
                })
                .catch(error => {
                    saving.value = false
                    errosOcurs.value = error.response.data
                    if (error.response.status === 422) {
                        serverErrors.value = error.response.data.errors
                    }
                    else {
                        context.root.$swal({
                            icon: 'error',
                            title: 'Server Error',
                            text: 'Something went wrong. See tech support',
                            showConfirmButton: true,
                            customClass: {
                                confirmButton: 'btn btn-danger',
                            },
                            buttonsStyling: false,
                        })
                    }
                })
        }

        const confirmDelete = async (id) => {
            const result = context.root.$swal({
                icon: 'info',
                title: 'System Info',
                text: 'Are you sure you want to delete the Box?',
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!',
                cancelButtonText: 'No, cancel',
                customClass: {
                    confirmButton: 'btn btn-info',
                    cancelButton: 'btn btn-danger ml-2', // Optional: Customize the cancel button class
                },
                buttonsStyling: true,
            }).then((result) => {

                if (result.value) {
                    remove(id)
                } else {
                    context.root.$swal({
                        icon: 'info',
                        title: 'Cancelled',
                        text: 'Your Box is safe you cancell deletion of it',
                        showConfirmButton: true,
                        customClass: {
                            confirmButton: 'btn btn-info',
                        },
                        buttonsStyling: true,
                    })
                }
            })

        }
        const deleteItem = async () => {
            context.root.$swal({
                icon: 'info',
                title: 'Cancelled',
                text: 'Your Box is safe you cancell deletion of it',
                showConfirmButton: true,
                customClass: {
                    confirmButton: 'btn btn-info',
                },
                buttonsStyling: true,
            })
        }

        const remove = async (id) => {
            await store.dispatch('cromis-requisition/remove', id)
                .then(response => {
                    refetch()
                })
                .catch(error => {
                    context.root.$swal({
                        icon: 'error',
                        title: 'Server Error',
                        text: 'Something went wrong. See tech support',
                        showConfirmButton: true,
                        customClass: {
                            confirmButton: 'btn btn-danger',
                        },
                        buttonsStyling: true,
                    })
                })
        }

        return {
            // Data
            selections,
            formSelections,
            levels,
            children,
            requisition,
            range,
            selectedOwner: null,
            addedElement: null,
            clientName: JSON.parse(localStorage.getItem('userData')).client,
            options: [
                { value: null, text: '-- Select Box Type --' },
                { value: '1', text: 'Bale Ticket' },
                { value: '2', text: 'Luggage' },
            ],
            fetch,
            columns,
            perPage,
            currentPage,
            totalRecords,
            from,
            to,
            meta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            records,
            refetch,
            resolveStatusVariant,
            resolveReceivedVariant,
            viewPcn,
            // Methods

            required,
            saving,
            serverErrors,
            dataForm,
            myModal,
            myModalPcn,
            myViewModal,
            requisitionDetail,
            filter,
            markets,
            locations,
            sales,
            changeSale,
            changeSociet,
            changeRegion,
            myRangeModal,
            changeFilters,
            changeFormSelection,
            locationFilter,
            societyFilter,
            marketFilter,
            invokeCreateForm,
            invokeUpdateForm,
            invokeRangeUpdateForm,
            approveRequisition,
            submit,
            submitUpdateRange,
            isFormValid,
            handleCreate,
            handleUpdate,
            handleUpdateRange,
            remove,
            addElement,
            removeRow,
            companies,
            societies,
            confirmDelete,
            deleteItem,
            errosOcurs,
            locId,
            role,
            getAreaMarket,
            getAreaMarketSale,
            handleApprove,
            handleCheckboxChange,
            tikets,
            totalWeight,
            tValue
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';
</style>